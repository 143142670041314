import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Login } from "./components/Login/Login";
import "./App.css";

function App() {
  return <Login />;
}

export default App;
