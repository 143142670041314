import React, { useState, useEffect, Fragment } from "react";
import { getQRCode } from "../../API/api.jsx";
import { CiLock } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import QRCodeComponent from "./QRCodeComponent";
import Countdown from "react-countdown";
import "../../App.css";

export const Login = () => {
    const [qrcode, setQrcode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [attemptCount, setAttemptCount] = useState(0); // Retry count
    const [openTime, setOpenTime] = useState(Date.now() + 120000);
    var id;
    const fetchQRCode = async (token) => {
        if (attemptCount >= 5) return; // Stop after 5 retries
        try {
            setLoading(true);
            const response = await getQRCode(token);
            console.log('response', response);
            if (response.data.status !== "Already in progress") {
                console.log("not same");
                setQrcode(response.data);
                setLoading(false);
            }
        } catch {
            setQrcode(null);
            setLoading(false);
            clearInterval(id);
        } finally {
            setAttemptCount((prev) => prev + 1); // Increment retry count
        }
    };

    useEffect(() => {
        const path = window.location.pathname;
        const token = path.substring(path.lastIndexOf("/") + 1);

        if (!token) {
            setQrcode(null);
            setLoading(false);
            return;
        }

        setOpenTime(Date.now() + 120000);
        fetchQRCode(token); // Initial fetch

        // Start timer and API retries
        id = setInterval(() => {
            if (attemptCount < 15) {
                fetchQRCode(token); // Fetch new QR code
            } else {
                clearInterval(id); // Stop interval after 5 retries
            }
        }, 30000);
        console.log("interval set");
        return () => clearInterval(id); // Cleanup on component unmount
    }, []);

    // Countdown Renderer
    const countdownRenderer = ({ minutes, seconds }) => {
        return (
            <span>
                {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
            </span>
        );
    };

    return (
        <Fragment>
            <header
                className="d-flex align-items-center p-3"
                style={{
                    color: "green",
                    fontSize: "2rem",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    zIndex: 1000,
                }}
            >
                <FaWhatsapp style={{ marginRight: "10px", fontSize: "3rem" }} />
                <h3 style={{ margin: 0 }}>myCRMWhatsapp</h3>
            </header>

            <main
                className="d-flex align-items-center justify-content-center vh-100"
                style={{ backgroundColor: "#f0f2f5", paddingTop: "80px" }}
            >
                <div
                    className="d-flex flex-column align-items-center border rounded p-4 bg-white shadow"
                    style={{
                        maxWidth: "900px",
                        width: "100%",
                        borderRadius: "12px",
                    }}
                >
                    <header
                        className="mb-4"
                        style={{ borderBottom: "1px solid #ddd", paddingBottom: "20px" }}
                    >
                        <h2 style={{ color: "#075e54", fontWeight: "bold" }}>
                            Login into myCRMWhatsapp
                        </h2>
                    </header>

                    {/* Display Timer and Retry Count */}
                    <div
                        className="d-flex justify-content-between align-items-center w-100 mb-4"
                        style={{
                            padding: "15px",
                            border: "1px solid #ddd",
                            alignContent: "center",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            fontSize: "11px",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: 'row', width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                            <div
                                style={{
                                    color: "#075e54",
                                    fontWeight: "bold",
                                }}
                            >
                                Please note it can take up to two minutes for your QR code to
                                appear.{" "}<Countdown date={openTime} renderer={countdownRenderer} />
                            </div>
                            <div
                                style={{
                                    color: "#075e54",
                                    fontWeight: "bold",
                                }}
                            >
                                Processing: {attemptCount}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex w-100">
                        <section
                            className="text-left pe-4"
                            style={{
                                flex: 1,
                                color: "#4a4a4a",
                                fontSize: "14px",
                                lineHeight: "1.5",
                            }}
                        >
                            <h4 className="mb-3" style={{ color: "#075e54" }}>
                                Use WhatsApp on your computer
                            </h4>
                            <p>Scan this QR code with your phone to log in:</p>
                            <ol>
                                <li>Open WhatsApp on your phone.</li>
                                <li>
                                    Tap <strong>Menu</strong> or <strong>Settings</strong> and
                                    select <strong>Linked Devices</strong>.
                                </li>
                                <li>
                                    Point your phone's camera at this screen to scan the QR code.
                                </li>
                            </ol>
                        </section>

                        <section
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{
                                flex: 1,
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                backgroundColor: "#fff",
                                padding: "10px",
                                textAlign: "center",
                            }}
                        >
                            <QRCodeComponent loading={loading} qrcode={qrcode} />
                        </section>
                    </div>

                    <p className="text-center mt-5">
                        <CiLock style={{ fontSize: "20px", fontWeight: "bold" }} /> Your
                        personal messages are end-to-end encrypted
                    </p>
                </div>
            </main>
        </Fragment>
    );
};
