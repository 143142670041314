import axios from "axios";

// Create an Axios instance
const api = axios.create({
  baseURL: "https://d2rg8bmqc4o8iv.cloudfront.net", // Your backend server base URL
  headers: {
    "Content-Type": "application/json", // Default content type if needed
  },
});

// Define the function to get the QR code
export const getQRCode = async (token) => {
  console.log('getQRCode called', token);
  
  const data = {
    location_id: token,
  };

  try {
    // Send POST request to the server
    const response = await api.post("/login", data,{
      timeout: 1800000 // 10 seconds
    });
    console.log("response", response);
    return response; // Assuming the QR code is in the response data
  } catch (error) {
    console.error("Error fetching QR code:", error);
    throw error; // Re-throw the error so it can be handled elsewhere
  }
};
