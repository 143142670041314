import React from "react";
import QRCode from "react-qr-code";

const QRCodeComponent = ({ loading, qrcode }) => {
    if (loading) {
        return (
            <div
                className="spinner-border text-success"
                role="status"
                style={{ width: "2rem", height: "2rem" }}
            >
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    }

    if (qrcode && qrcode.status) {
        return (
            <div style={{ height: "auto", margin: "0 auto", maxWidth: 230, width: "100%" }}>
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrcode.qr_code}
                    viewBox={`0 0 256 256`}
                />
            </div>
        );
    }

    return (
        <div
            style={{
                color: "red",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "16px",
                padding: "20px",
            }}
        >
            <p>{qrcode?.errors || "No QR code available, make sure your url includes the token"}</p>
        </div>
    );
};

export default QRCodeComponent;
